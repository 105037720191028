import React from "react";
// import PropTypes from "prop-types";
import styles from "../PeopleBody/PeopleBody.module.scss";
import { Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

const ListUser = ({
  item,
  handleStaffChange,
  showDeleteConfirm,
  index,
  loading,
}) => {
  let currentUserEmail = "remove@this";
  return (
    <div className={styles.PeopleBody__staff__body} key={index}>
      <div className={styles.PeopleBody__staff__body__name}>
        <span data-testid="PeopleBody_ListUsers_name">
          {item.staff.firstName} {item.staff.lastName}
        </span>
        <p>{item.staff.email}</p>
      </div>

      <div className={styles.PeopleBody__staff__body__actionContainer}>
        <Select
          data-testid="select-role-field"
          value={item.roleName}
          onChange={(value) => handleStaffChange(value, item.staff.id)}
          className={styles.PeopleBody__staff__body__actionContainer__select}
          loading={loading}
          disabled={currentUserEmail === item.staff.email}
        >
          <Option value="admin">Admin</Option>
          <Option value="member">Member</Option>
        </Select>
        {currentUserEmail !== item.staff.email ? (
          <div
            className={styles.PeopleBody__staff__body__actionContainer__delete}
          >
            <DeleteOutlined
              onClick={() =>
                showDeleteConfirm(
                  `${item.staff.firstName} ${item.staff.lastName}`,
                  item.staff.id
                )
              }
              className={
                styles.PeopleBody__staff__body__actionContainer__delete__dltIcon
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ListUser.propTypes = {};

ListUser.defaultProps = {};

export default ListUser;
