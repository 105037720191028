import 'symbol-observable';
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./slices/authSlice";
import timerReducer from "./slices/timerSlice";
import userReducer from "./slices/userSlice";
import searchReducer from "./slices/searchSlice";
import languageReducer from "./slices/languageSlice";
import nodeReducer from "./slices/nodeSlice";
import peopleReducer from "./slices/peopleSlice";
import onboardingReducer from "./slices/onboardingSlice";
import contactReducer from "./slices/contactSlice";
import channelReducer from "./slices/channelSlice";
import billingReducer from "./slices/billingSlice";
import chatReducer from "./slices/chatSlice";
import themeReducer from "./slices/themeSlice";
import locationReducer from "./slices/locationSlice"

const reducers = combineReducers({
  auth: authReducer,
  timer: timerReducer,
  user: userReducer,
  search: searchReducer,
  language: languageReducer,
  node: nodeReducer,
  people: peopleReducer,
  onboarding: onboardingReducer,
  contact: contactReducer,
  channel: channelReducer,
  billing: billingReducer,
  chat: chatReducer,
  theme: themeReducer,
  location: locationReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
});

const persistor = persistStore(store);

export { store, persistor };
export default store;
