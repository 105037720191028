import React from 'react';
import style from './QueriesList.module.scss';
import { Table, Tag } from 'antd';

const columns = [
  {
    title: 'Inquiries',
    dataIndex: 'type',
    key: 'inqtypeuiry',
    responsive: ['xs', 'sm', 'md', 'lg'],
    ellipsis: true,
    render: (text, record) => {
      let color = record.type === 'product-related' ? '#E6ECEE' : '#EDEEE6';
      if (record.type === 'product-related') {
        return (
          <>
            <span>
              Product-type: {record.type_of_product}<br />
              Query: {record.query}
              <br />
              <Tag style={{ color: 'black' }} color={color}>
                {record.type}
              </Tag>
            </span>
          </>
        )
      }
      else {
        return (
          <>
            <span>
              Query: {record.query}
              <br />
              <Tag style={{ color: 'black' }} color={color}>
                {record.type}
              </Tag>
            </span>
          </>
        )

      }
    }
  },
  {
    title: 'No. of times triggered',
    dataIndex: 'count',
    key: 'count',
    responsive: ['xs', 'sm', 'md', 'lg'],
  },
];

const TopQueries = ({data}) => {
  return (
    <div className={style.queryList}>
      <h2 className={style.chartHeader}>Top Queries Raised with Chatbot</h2>
      <div style={{ maxHeight: '450px', overflowY: 'scroll' }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default TopQueries;
