  import React from "react";
  import styles from "./RangePickerWidget.module.scss"
  import { DatePicker, Space } from "antd";
  import moment from "moment";

  const { RangePicker } = DatePicker;

  const RangePickerWidget = ({ onDateRangeChange, value }) => {
    const handleDateRangeChange = (dates) => {
      onDateRangeChange(dates);
    };

  const defaultStartDate = moment().format("yyyy/MM/DD");

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        className={styles.rangepicker}
        onChange={handleDateRangeChange}
        // defaultValue={startDate && endDate ? [startDate, endDate] : undefined}
        placeholder={[defaultStartDate, defaultStartDate]}
      />
    </Space>
  );
};

export default RangePickerWidget;
