import React, { useEffect, useState } from "react";
import styles from "./PeopleBody.module.scss";
import { Button, Divider, Modal, Input } from "antd";
import { UserAddOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import ListUser from "../ListUser/ListUser";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InvitePeopleModal from "src/components/InvitePeople/components/InvitePeopleModal/InvitePeopleModal";
import {
  getStaffPeople,
  peopleDelete,
  peopleUpdate,
} from "src/api/OrganizationApi";
import { getActiveOrgId } from "src/store/slices/userSlice";
const { Search } = Input;

const PeopleBody = (props) => {
  const { confirm } = Modal;
  const { t } = useTranslation();

  const organizationId = useSelector(getActiveOrgId);

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [roleChangeId, setRoleChangeId] = useState(null);

  const [people, setPeople] = useState([]);

  useEffect(() => {
    setLoading(true);
    let payload = { organizationId };
    roleChangeId === null &&
      getStaffPeople(payload)
        .then(({ data }) => {
          if (data.success) {
            setPeople(data.data.staff);
          }
        })
        .finally(() => setLoading(false));
  }, [roleChangeId]);

  const filterPeople = (people, searchTerm) => {
    if (!searchTerm) {
      return people;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return people?.filter((item) => {
      const { email, firstName, lastName } = item.staff;
      return (
        email?.toLowerCase().includes(lowerCaseSearchTerm) ||
        firstName?.toLowerCase().includes(lowerCaseSearchTerm) ||
        lastName?.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
  };
  const handleStaffChange = async (value, id) => {
    setLoading(true);
    setRoleChangeId(id);
    let payload = { organizationId, staffId: id, role: value };
    peopleUpdate(payload)
      .then(({ data }) => {
        if (data.success) {
          setRoleChangeId(null);
        }
      })
      .finally(() => setLoading(false));
  };

  const filteredPeople = filterPeople(people, searchTerm);

  const deleteStaffMember = async (id) => {
    setLoading(true);
    setRoleChangeId(id);
    let payload = { organizationId, staffId: id };
    peopleDelete(payload)
      .then(({ data }) => {
        if (data.success) {
          setRoleChangeId(null);
        }
      })
      .finally(() => setLoading(false));
  };

  const showDeleteConfirm = (userName, id) => {
    confirm({
      title: "Remove Team member",
      icon: (
        <ExclamationCircleOutlined
          className={
            styles.PeopleBody__staff__body__actionContainer__dltIcon__modalIcon
          }
        />
      ),
      content: (
        <p>
          Are you sure you want to remove <b>{userName}</b>? This action is not
          reversible.
        </p>
      ),
      okText: "Remove",
      okType: "danger primary",
      cancelText: "Cancel",
      onOk: () => deleteStaffMember(id),
    });
  };

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={styles.PeopleBody}>
      <div
        className={styles.PeopleBody__invitebutton}
        data-testid="PeopleBody__invitebutton"
      >
        <Search
          placeholder={t("Search")}
          className={styles.PeopleBody__invitebutton__search}
          allowClear
          onChange={onSearch}
          style={{
            width: 281,
          }}
        />
        <InvitePeopleModal
          triggerElement={
            <Button
              key="invite"
              type="primary"
              icon={<UserAddOutlined />}
              size="medium"
              style={{backgroundColor:'var(--primary-color)'}}
            >
              {t("Invite People")}
            </Button>
          }
        />
      </div>
      <div
        data-testid="PeopleBody__ListUsers"
        className={styles.PeopleBody__staff}
      >
        <div className={styles.PeopleBody__staff__header}>
          <span>{t("Name")}</span>
          <span>{t("Access")}</span>
        </div>
        <Divider />
        {filteredPeople?.map((item, index) => (
          <ListUser
            key={index}
            item={item}
            handleStaffChange={handleStaffChange}
            showDeleteConfirm={showDeleteConfirm}
            index={index}
            loading={loading && roleChangeId === item.staff.id}
          />
        ))}
      </div>
    </div>
  );
};

export default PeopleBody;
